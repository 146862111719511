import { render, staticRenderFns } from "./NavbarBananaPage.vue?vue&type=template&id=ad69579c&lang=pug"
import script from "./NavbarBananaPage.vue?vue&type=script&lang=js"
export * from "./NavbarBananaPage.vue?vue&type=script&lang=js"
import style0 from "./NavbarBananaPage.vue?vue&type=style&index=0&id=ad69579c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AlgoliaSearch: require('/dashboard/search/algolia-search/AlgoliaSearch.vue').default,PugImg: require('/dashboard/pug-img/PugImg.vue').default,NavbarBananaPageButtons: require('/dashboard/navigation/navbar-banana-page/NavbarBananaPageButtons.vue').default,NavbarBananaPageHelpLink: require('/dashboard/navigation/navbar-banana-page/NavbarBananaPageHelpLink.vue').default})
