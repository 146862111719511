import { render, staticRenderFns } from "./NavbarBanana.vue?vue&type=template&id=44409482&lang=pug"
import script from "./NavbarBanana.vue?vue&type=script&lang=js"
export * from "./NavbarBanana.vue?vue&type=script&lang=js"
import style0 from "./NavbarBanana.vue?vue&type=style&index=0&id=44409482&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {NavbarBananaAccountsDropdown: require('/dashboard/navigation/navbar-banana/NavbarBananaAccountsDropdown.vue').default,NavbarBananaCacheStatus: require('/dashboard/navigation/navbar-banana/NavbarBananaCacheStatus.vue').default,FeatherIcon: require('/helpers/FeatherIcon.vue').default,NavbarBananaDropdown: require('/dashboard/navigation/navbar-banana/NavbarBananaDropdown.vue').default,NavbarBananaNotifications: require('/dashboard/navigation/navbar-banana/NavbarBananaNotifications.vue').default,KeyboardShortcuts: require('/dashboard/modals/keyboard-shortcuts/KeyboardShortcuts.vue').default,WebsiteColorPalette: require('/dashboard/modals/website-color-palette/WebsiteColorPalette.vue').default,CopyClientModal: require('/dashboard/modals/copy-client-modal/CopyClientModal.vue').default})
